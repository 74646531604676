import React from 'react'

import SEO from 'components/seo'

const NotFoundPage = () => (
  <>
    <SEO title='404: Not found' />
    <div className='error_page'>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </>
)

export default NotFoundPage
